import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql, useStaticQuery } from 'gatsby'

import './style.scss'

import studioBg from '../../images/ndreams_studio_bg.jpg'
import studioLogo from '../../images/nDreams_Studio_White.png'

import elevationBg from '../../images/elevation_bg.png'
import elevationLogo from '../../images/Elevation_Colour_White.png'

import orbitalBg from '../../images/img-orbital-nav-highlight.jpg'
import orbitalLogo from '../../images/Orbital_White.png'

import nearlightBg from '../../images/nearlight_bg.jpg'
import nearlightLogo from '../../images/nearlight_logo_white.png'

const StudioShowcase = ({ backgroundColour, heading, body, verticalLabel }) => {
    const { orbitalStudio, ndreamsStudio, elevationStudio, nearlightStudio } =
        useStaticQuery(graphql`
            query studioShowcaseStudios {
                orbitalStudio: datoCmsOrbitalStudio {
                    enabled
                }
                ndreamsStudio: datoCmsNdreamsStudio {
                    enabled
                }
                elevationStudio: datoCmsElevationGlobalSeo {
                    enabled
                }
                nearlightStudio: datoCmsNearlightStudio {
                    enabled
                }
            }
        `)
    const enabled = [
        orbitalStudio,
        ndreamsStudio,
        elevationStudio,
        nearlightStudio,
    ].filter((studio) => studio.enabled !== false).length

    return (
        <div
            className={`image-with-text relative ${
                backgroundColour === 'Grey' ? 'bg-bare' : 'bg-white'
            }`}
        >
            <div className="container mx-auto px-10 relative">
                <div className="md:grid md:grid-cols-14 md:grid-flow-col">
                    {verticalLabel && (
                        <div
                            className={`py-12 md:py-16 xl:py-28 hidden xl:block `}
                        >
                            <div className="vert-label">{verticalLabel}</div>
                        </div>
                    )}
                    <div
                        className={`flex flex-col items-start justify-center py-12 md:py-16 xl:py-28 col-span-14 md:col-span-6 xl:col-span-5 xl:col-start-2 2xl:pr-4`}
                    >
                        {heading && (
                            <h2 className="line-styled-heading mb-8 md:mb-12 relative text-4xl sm:text-5xl lg:text-6xl 2xl:text-7xl font-extrabold uppercase">
                                <ReactMarkdown children={heading} />
                            </h2>
                        )}
                        {body && (
                            <ReactMarkdown
                                children={body}
                                className="wysiwyg"
                            />
                        )}
                    </div>
                    <div
                        className={`mb-12 md:mb-0 col-span-7 col-start-8 flex flex-col justify-center items-center`}
                    >
                        <div
                            className={`relative grid ${
                                enabled > 2
                                    ? 'grid-cols-1 md:grid-cols-2'
                                    : 'grid-cols-1 max-w-lg'
                            } gap-8 w-full items-center justify-around`}
                        >
                            {ndreamsStudio.enabled !== false ? (
                                <div className="relative col-span-1 aspect-video hover:scale-105 transition-all duration-300">
                                    <a href="https://www.studio.ndreams.com">
                                        <img
                                            className="w-full absolute h-full bg-cover top-0 left-0"
                                            src={studioBg}
                                        />
                                        <div className="w-full h-full absolute top-0 left-0 bg-black bg-opacity-40" />
                                        <img
                                            className="w-4/5 absolute h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                            src={studioLogo}
                                        />
                                    </a>
                                </div>
                            ) : null}

                            {elevationStudio.enabled !== false ? (
                                <div className="relative col-span-1 aspect-video hover:scale-105 transition-all duration-300">
                                    <a href="https://www.elevation.ndreams.com">
                                        <img
                                            className="w-full absolute h-full bg-cover top-0 left-0"
                                            src={elevationBg}
                                        />
                                        <div className="w-full h-full absolute top-0 left-0 bg-black bg-opacity-40" />
                                        <img
                                            className="w-4/5 absolute h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                            src={elevationLogo}
                                        />
                                    </a>
                                </div>
                            ) : null}

                            {orbitalStudio.enabled !== false ? (
                                <div className="relative col-span-1 aspect-video hover:scale-105 transition-all duration-300">
                                    <a href="https://www.orbital.ndreams.com">
                                        <img
                                            className="w-full absolute h-full bg-cover top-0 left-0"
                                            src={orbitalBg}
                                        />
                                        <div className="w-full h-full absolute top-0 left-0 bg-black bg-opacity-40" />
                                        <img
                                            className="w-4/5 absolute h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                            src={orbitalLogo}
                                        />
                                    </a>
                                </div>
                            ) : null}

                            {nearlightStudio.enabled !== false ? (
                                <div className="relative col-span-1 aspect-video hover:scale-105 transition-all duration-300">
                                    <a href="https://nearlight.ndreams.com/">
                                        <img
                                            className="w-full absolute h-full bg-cover top-0 left-0"
                                            src={nearlightBg}
                                        />
                                        <div className="w-full h-full absolute top-0 left-0 bg-black bg-opacity-40" />
                                        <img
                                            className="w-4/5 absolute h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                            src={nearlightLogo}
                                        />
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudioShowcase
